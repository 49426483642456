<template>
    <auth-page-template heading="Create an account" size="lg" :back-link="true">
        <template #subSection>
            <div class="text-center">
                <p class="mb-41">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                </p>
            </div>
        </template>
        <template #default>
            <s-form class="row">
                <div class="col-12">
                    <div class="px-lg-9">
                        <validated-select class="c-input-transparent c-input-select"
                                          placeholder="Business"></validated-select>
                    </div>
                </div>
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input class="c-input-transparent" placeholder="First Name*"></validated-input>
                </div>
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input placeholder="Last Name*"></validated-input>
                </div>
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input placeholder="Father/Husband's Name*"></validated-input>
                </div>
                <div class="col-12">
                    <validated-text-area class="c-textarea-lg" placeholder="Address*"></validated-text-area>
                </div>
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input type="tel" placeholder="+91"></validated-input>
                </div>
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input placeholder="Email*"></validated-input>
                </div>
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input placeholder="PAN/GSTIN*"></validated-input>
                </div>
                <div class="col-12 mt-4">
                    <h2 class="fs-lg-1 font-poppins-semibold">Authorized Signatory</h2>
                </div>
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input placeholder="Name*"></validated-input>
                </div>
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input placeholder="Email*"></validated-input>
                </div>
                <div class="col-12 col-lg-4 col-md-6">
                    <validated-input placeholder="Phone Number*"></validated-input>
                </div>
                <div class="col-12">
                    <validated-text-area class="c-textarea-lg" placeholder="Address*"></validated-text-area>
                </div>
                <div class="col-12 text-center">
                    <btn design="" class="btn-bg-white mt-4 px-5" size="lg" border-radius="4" text="Submit"></btn>
                </div>
            </s-form>
            <btn text="Open Modal" @click="$refs.popUpModal.show()"></btn>
            <btn text="payment success Modal" @click="$refs.paymentSuccessModal.show()"></btn>
            <!--            Modals-->
            <modal class="c-modal modal-body no-close header-primary header-none" :no-close-on-backdrop="false"
                   title=""
                   ref="popUpModal" width="25r" header-color="">
                <div class="fl-y fl-a-c pt-3 pb-4">
                    <img src="../../../assets/web/auth/checked-white-bg.png" alt="">
                    <h3 class="mt-5 fs-lg-5">Saved!</h3>
                    <p class="mt-3 px-5 text-center">
                        Your details have been saved successfully.
                    </p>
                    <btn design="" class="btn-bg-white font-poppins-semibold mt-4 px-5" size="lg" border-radius="4"
                         text="Proceed to payment"></btn>
                </div>
            </modal>
            <!--            Payment Success Modal-->
            <modal class="c-modal modal-body no-close header-primary header-none" :no-close-on-backdrop="false"
                   title=""
                   ref="paymentSuccessModal" width="25r" header-color="">
                <div class="fl-y fl-a-c pt-3 pb-4">
                    <img src="../../../assets/web/auth/checked-white-bg.png" alt="">
                    <h3 class="mt-5 fs-lg-5">Payment Successful</h3>
                    <p class="mt-3 px-5 text-center">
                        Thank You! Your transaction was successful!.
                    </p>
                    <router-link to="/login/" class="text-decoration-none">
                        <btn design="" class="btn-bg-white font-poppins-semibold mt-4 px-5" size="lg" border-radius="4"
                             text="Back to Login"></btn>
                    </router-link>
                </div>
            </modal>
        </template>
    </auth-page-template>
</template>

<script>
export default {
    name: 'CreateAccountBasicInfo'
};
</script>

<style scoped>

</style>
